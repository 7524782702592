import { connect } from 'react-redux'

import * as ui from '../../ui'

import { Fullscreen as Component } from './fullscreen'

function mapStateToProps(state) {
  return {
    isFullscreen: ui.getVisibility(state.ui, { id: 'fullscreen' }),
  }
}

const connector = connect(
  mapStateToProps,
  { onToggle: value => ui.toggleVisibility('fullscreen', value) }
)

export const Fullscreen = connector(Component)
