import * as R from 'ramda'
import * as recompose from 'recompose'
import querystring from 'querystring'
import url from 'url'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router-dom'

import * as casino from '@rushplay/casino'
import * as session from '@rushplay/session'

import * as Constants from '../../constants'
import * as configuration from '../../configuration'
import * as ui from '../../ui'
import { clientTypes } from '../../client-type'
import { getLanguage } from '../../combined-selectors'

import { GameLauncher as Component } from './game-launcher'

const ENVS = {
  staging: 'stg',
  production: 'prd',
}

function getAppTitle(game, isLottoGame) {
  if (game.provider && game.title) {
    if (isLottoGame) {
      return game.title
    } else {
      return `${game.provider} - ${game.title}`
    }
  }
}

function mapStateToProps(state, props) {
  const gameId = R.pathOr(
    R.path(['gameId'], props),
    ['match', 'params', 'gameId'],
    props
  )

  const provider = R.pathOr(
    R.path(['provider'], props),
    ['match', 'params', 'provider'],
    props
  )

  const name = R.pathOr(
    R.path(['name'], props),
    ['match', 'params', 'name'],
    props
  )

  const game = R.defaultTo(
    {},
    casino.selectors.getGame(state.casino, { id: gameId })
  )

  const token = R.defaultTo('', session.getSessionToken(state.session))
  const clientType = configuration.getClientType(state.configuration)
  const language = getLanguage(state)
  const mode = R.path(
    ['mode'],
    url.parse(props.location.search, true, true).query
  )

  const env = R.prop(configuration.getEnvironment(state.configuration), ENVS)
  const gameServerUrl = configuration.getGameServerUrl(state.configuration)
  const devicePixelRatio =
    process.browser && R.defaultTo('1', window.devicePixelRatio)

  const freeGames = R.path(
    ['free_games'],
    querystring.parse(R.drop(1, props.location.search))
  )

  const gamePath = url.format({
    pathname: `/games/${gameId}`,
    query: {
      brand: 'sic',
      client_type: clientType,
      env,
      free_games: freeGames,
      language,
      mode,
      token,
      dpr: devicePixelRatio,
    },
  })

  const widgetPath = url.format({
    pathname: `/pages/${provider}/${name}`,
    query: {
      brand: 'sic',
      client_type: clientType,
      env,
      language,
      token,
      dpr: devicePixelRatio,
    },
  })

  const gameUrl = provider
    ? `${gameServerUrl}${widgetPath}`
    : `${gameServerUrl}${gamePath}`

  return {
    authenticated: session.isSessionActive(state.session),
    appTitle: getAppTitle(game, props.isLottoGame),
    gameUrl: clientType !== clientTypes.UNKNOWN ? gameUrl : null,
    showGameBar: !props.isLottoGame && !props.isSportGame,
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    onClassicModeOpened: () => {
      const prevQueryParams = querystring.parse(
        R.drop(1, props.location.search)
      )
      const nextQueryParams = R.assoc('mode', 'classic', prevQueryParams)
      return dispatch(
        push(
          `${props.location.pathname}?${querystring.stringify(nextQueryParams)}`
        )
      )
    },
    onEnd: () =>
      dispatch([
        ui.toggleVisibility('game-view', false),
        ui.toggleVisibility('hideSupportChat', false),
      ]),
    onGameClosed: () =>
      dispatch(push(props.isLottoGame ? '/lotto' : '/casino')),
    onHistoryOpened: () => dispatch(push('/gambling-history')),
    onLimitsOpened: () => dispatch(push('/money-limits')),
    onStart: () =>
      dispatch([
        ui.toggleVisibility('game-view', true),
        ui.toggleVisibility('hideSupportChat', true),
      ]),
    onWalletOpened: iframe => {
      if (props.authenticated) {
        dispatch(ui.toggleVisibility(Constants.TransactionType.DEPOSIT, true))
        if (props.isLottoGame && iframe) {
          iframe.setAttribute('src', iframe.src)
        }
      } else {
        dispatch(push('/deposit'))
      }
    },
  }
}

const connector = R.compose(
  withRouter,
  connect(mapStateToProps),
  connect(null, mapDispatchToProps),
  recompose.branch(props => R.isNil(props.gameUrl), recompose.renderNothing)
)

export const GameLauncher = connector(Component)
