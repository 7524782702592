import * as ReactRedux from 'react-redux'

import * as CombinedSelectors from '../../../combined-selectors'
import * as Player from '../../../player'
import { getMinWithdrawAmountInCents } from '../../../minTransactionAmount'

import { WithdrawalLimitInfo as Component } from './withdrawal-limit-info'

function mapStateToProps(state, props) {
  const currency = CombinedSelectors.getCurrency(state)
  const withdrawalInformation = Player.getWithdrawalInformation(state.player)

  return {
    amount: props.amountCents,
    bonusBalanceCents: withdrawalInformation.bonusBalanceCents,
    limit: Player.getWithdrawalLimit(state.player),
    limitRemainder: Player.getWithdrawalLimitRemainder(state.player),
    minAmount: getMinWithdrawAmountInCents(currency),
    moneyBalanceCents: withdrawalInformation.moneyBalanceCents,
    sportsbookEnabled: CombinedSelectors.isSportsbookEnabled(state),
    sportWagerBalanceCents: withdrawalInformation.sportWagerBalanceCents,
    wagerBalanceCents: withdrawalInformation.wagerBalanceCents,
  }
}

export const WithdrawalLimitInfo = ReactRedux.connect(mapStateToProps)(
  Component
)
