import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'

import styled from '@emotion/styled'

import { GameWrapper } from '../game-wrapper'

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background-color: ${props => props.background || '#000000'};
`

export function GameLauncher(props) {
  const iframeRef = useRef(null)
  useEffect(() => {
    props.onStart()
    return () => props.onEnd()
  }, [])

  useEffect(() => {
    function gameEventListener(event) {
      const action = event.data || {}
      switch (action.type) {
        case '@rushplay/gamer/GAME_CLOSED': {
          return props.onGameClosed()
        }
        case '@rushplay/gamer/BET_HISTORY_OPENED': {
          return props.onHistoryOpened()
        }
        case '@rushplay/gamer/CLASSIC_MODE_OPENED': {
          return props.onClassicModeOpened()
        }
        case '@rushplay/gamer/LIMITS_OPENED': {
          return props.onLimitsOpened()
        }
        case '@rushplay/gamer/WALLET_OPENED': {
          return props.onWalletOpened(iframeRef.current)
        }
      }
    }
    window.addEventListener('message', gameEventListener)
    return () => window.removeEventListener('message', gameEventListener)
  }, [])

  return (
    <GameWrapper showGameBar={props.showGameBar} iframeRef={iframeRef}>
      <Helmet title={props.appTitle} />
      <Iframe
        background={props.background}
        ref={iframeRef}
        sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
        src={props.gameUrl}
      />
    </GameWrapper>
  )
}

GameLauncher.propTypes = {
  appTitle: PropTypes.string,
  background: PropTypes.string,
  gameUrl: PropTypes.string.isRequired,
  token: PropTypes.string,
  showGameBar: PropTypes.bool,
  onClassicModeOpened: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
  onGameClosed: PropTypes.func.isRequired,
  onHistoryOpened: PropTypes.func.isRequired,
  onLimitsOpened: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  onWalletOpened: PropTypes.func.isRequired,
}
