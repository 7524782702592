import PropTypes from 'prop-types'
import React from 'react'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import * as Constants from '../constants'
import { H2 } from '../components/common/headings'
import { HtmlContent } from '../components/common/html-content'
import { PrimaryButton } from '../components/common/button-simple'

const Hand = styled.div`
  width: 63px;
  height: 90px;
  mask: url(/images/hand.svg) no-repeat center;
  background-color: ${t.color('primary')};
`

export function TransactionSuccess(props) {
  const i18n = useI18n()
  const amountCents = Forms.useField('#/properties/amount', {
    noRegister: true,
  }).value

  return (
    <Common.Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      px="0"
    >
      <H2
        style={{
          fontSize: '22px',
          fontWeight: 700,
          lineHeight: '32px',
        }}
      >
        {i18n.translate('wallet.transaction-success.title')}
      </H2>
      <Common.Space py="2">
        <Hand />
      </Common.Space>
      <Common.Box py="0" fontSize="14px" textAlign="center">
        <HtmlContent
          html={{
            __html: i18n.translate(
              `wallet.transaction-success.${props.transactionType}.content`,
              {
                amount: amountCents,
              }
            ),
          }}
          styleOverrides={{
            letterSpacing: '1px',
            lineHeight: '20px',
          }}
        />
      </Common.Box>
      <Common.Box pt="1" width="100%">
        <PrimaryButton stretched onClick={props.onClose}>
          {i18n.translate('wallet.transaction-success.close')}
        </PrimaryButton>
      </Common.Box>
    </Common.Box>
  )
}

TransactionSuccess.propTypes = {
  transactionType: PropTypes.oneOf([
    Constants.TransactionType.DEPOSIT,
    Constants.TransactionType.WITHDRAWAL,
  ]),
  onClose: PropTypes.func.isRequired,
}
