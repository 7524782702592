import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import PropTypes from 'prop-types'
import querystring from 'querystring'
import { STORAGE_TYPE, getItem, setItem } from 'redux-effects-localstorage'
import { bind } from 'redux-effects'
import { push as redirectTo } from 'react-router-redux'

import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'

import * as configuration from '../configuration'
import * as lookup from '../lookup'
import * as combinedSelectors from '../combined-selectors'
import * as player from '../player'
import * as promotions from '../promotions'
import * as session from '../session'
import * as ui from '../ui'
import * as Constants from '../constants'
import { clientTypes } from '../client-type'
import { useTransactionAmountLimits } from '../use-transaction-amount-limits'

import { Spinner } from './spinner'
import { Transaction } from './transaction'
import { TransactionStep } from './transaction-steps'

function useAmountDataSchema(transactionType) {
  const { limits, loading } = useTransactionAmountLimits(transactionType)
  const isFetchingTransactionLimits = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: ['FETCH_DEPOSIT_INFORMATION', 'FETCH_WITHDRAW_INFORMATION'],
    })
  )

  const depositLimitRemainder = ReactRedux.useSelector(state =>
    player.getDepositLimitRemainder(state.player)
  )
  const withdrawalLimitRemainder = ReactRedux.useSelector(state =>
    player.getWithdrawalLimitRemainder(state.player)
  )
  const moneyBalanceCents = ReactRedux.useSelector(state =>
    player.getWithdrawableBalanceCents(state.player)
  )

  const playerLimitMaximum =
    transactionType === Constants.TransactionType.DEPOSIT
      ? depositLimitRemainder
      : Math.min(withdrawalLimitRemainder, moneyBalanceCents)

  const commonLimitMax = limits?.max || Number.MAX_SAFE_INTEGER

  const dataSchema = React.useMemo(
    () =>
      !loading &&
      !isFetchingTransactionLimits && {
        properties: {
          amount: {
            type: 'number',
            maximum: Math.min(playerLimitMaximum, commonLimitMax),
            minimum: limits.min ? limits.min : 0,
          },
          campaign: {
            type: 'number',
          },
          bonusOffer: {
            type: 'number',
          },
        },
        required: ['amount'],
        type: 'object',
      },
    [limits, loading, playerLimitMaximum, isFetchingTransactionLimits]
  )
  return { dataSchema, limits, loading: loading || isFetchingTransactionLimits }
}

function getAmount(
  state,
  query,
  minDepositAmount,
  isDeposit,
  rememberedDepositValue
) {
  if (query.amount) {
    return R.max(parseInt(query.amount), minDepositAmount)
  }

  if (!R.isNil(rememberedDepositValue)) {
    return rememberedDepositValue
  }

  if (isDeposit) {
    return player.getInitialDepositAmount(state.player)
  }

  return null
}

function getPayerInitialValues(state) {
  return {
    bank_id_type: R.always('remote_personal_number'),
    email: session.getPlayerEmail(state.session),
    beneficiaryStreet: session.getPlayerStreet(state.session),
    beneficiaryZip: session.getPlayerZip(state.session),
    beneficiaryCity: session.getPlayerCity(state.session),
    beneficiaryState: session.getPlayerCity(state.session),
    countryCode: lookup.getCountryCode(state.lookup),
    ssn: session.getPersonalNumber(state.session),
    state: player.getPlayerProvince(state.player),
  }
}

function getConfig(state, query) {
  const features = configuration.getFeatures(state.configuration)

  return {
    clientType: configuration.getClientType(state.configuration),
    cancelPendingWithdrawals: ui.getVisibility(state.ui, {
      id: 'cancelPendingWithdrawals',
    }),
    depositCount: parseInt(player.getDepositCount(state.player)),
    depositNumber: player.getDepositCount(state.player),
    license: Jurisdiction.getLicense(state.jurisdiction),
    orderId: query.order_id,
    rememberDepositAmount: features.rememberDepositAmount,
    welcomeOfferMinDeposit: combinedSelectors.getWelcomeOfferMinDepositLimit(
      state
    ),
    showPendingTransactions: combinedSelectors.isCancelPendingWithdrawalsVisible(
      state
    ),
  }
}

export function TransactionContainer(props) {
  const [rememberedDepositValue, setRememberedDepositValue] = React.useState(
    null
  )
  const dispatch = ReactRedux.useDispatch()
  const { dataSchema, limits, loading } = useAmountDataSchema(
    props.transactionType
  )
  const isDeposit = props.transactionType === Constants.TransactionType.DEPOSIT
  const minAmount = limits ? limits.min : null
  const location = ReactRouterDom.useLocation()
  const query = querystring.parse(R.drop(1, location.search))
  const fieldBuilderInitialValues = {
    amount: ReactRedux.useSelector(state =>
      getAmount(state, query, minAmount, isDeposit, rememberedDepositValue)
    ),
    bonusOffer: query.bonusOffer ? parseInt(query.bonusOffer) : undefined,
    campaign: query.campaign ? parseInt(query.campaign) : undefined,
  }
  const payerInitialValues = ReactRedux.useSelector(getPayerInitialValues)
  const config = ReactRedux.useSelector(state => getConfig(state, query))

  React.useEffect(() => {
    if (!query.amount && config.rememberDepositAmount) {
      dispatch(
        bind(
          getItem('DEPOSIT_AMOUNT', STORAGE_TYPE.session),
          value => !R.isNil(value) && setRememberedDepositValue(value)
        )
      )
    }
  })

  function handleStepChange(step, values, rememberDepositAmount, data) {
    if (step === TransactionStep.Payer) {
      rememberDepositAmount &&
        isDeposit &&
        R.pathOr(false, ['amount'], values) &&
        dispatch(
          setItem(
            'DEPOSIT_AMOUNT',
            R.path(['amount'], values),
            STORAGE_TYPE.session
          )
        )
    }

    if (step === TransactionStep.TransactionSuccess && isDeposit) {
      R.path(['depositNumber'], data) === 0 &&
        dispatch(
          Analytics.bonus({
            value: R.path(['amount'], values),
            claimed: R.path(['bonusOffer'], values),
          })
        )
    }

    const nextQuery =
      values == null || R.isEmpty(values)
        ? query
        : R.compose(
            R.assocPath(['amount'], values.amount),
            R.assocPath(['bonusOffer'], values.bonusOffer),
            R.assocPath(['campaign'], values.campaign)
          )(query)

    const nextSearch = `?${querystring.stringify(nextQuery)}`
    const nextLocation = R.pipe(
      R.assocPath(['search'], nextSearch),
      R.assocPath(['state', 'step'], step)
    )(location)

    dispatch(redirectTo(nextLocation))
  }

  function handleCleanUrl() {
    const nextQuery = R.compose(R.dissoc('campaign'), R.dissoc('amount'))(query)

    const nextSearch = `?${querystring.stringify(nextQuery)}`

    //It's necessary because when player close it clicking in "Manage your deposit limits" he/she is redirected to URL and wallet is closed
    //if we don't get pathname from URL it would be replaced by pathname in props.location
    //TODO find a better solution
    const windowPathname =
      window.location.pathname.search(/^\/\w\w\//) > -1
        ? R.replace(/^\/\w\w\//, '/', window.location.pathname)
        : window.location.pathname

    const pathname = R.any(item => R.includes(item, windowPathname), [
      'money-limits',
    ])
      ? windowPathname
      : location.pathname

    const nextLocation = R.pipe(
      R.assocPath(['search'], nextSearch),
      R.assocPath(['pathname'], pathname)
    )(location)

    dispatch(redirectTo(nextLocation))
  }

  function handleClaimOffer(id) {
    dispatch(Api.claimDepositOffer(id, { version: 2 }))
  }

  function handleClaimCampaign(id) {
    dispatch(
      Api.optOutAllDepositCampaigns({
        success: () => Api.optInCampaign(id, { version: 2 }),
        failure: () => Api.optInCampaign(id, { version: 2 }),
        version: 2,
      })
    )
  }

  function handleCleanup() {
    dispatch([
      handleCleanUrl(),
      Api.deleteClaimedDepositOffer({ version: 2 }),
      Api.optOutAllDepositCampaigns({ version: 2 }),
    ])
  }

  function handleInit() {
    handleStepChange(TransactionStep.Amount, {})
    if (isDeposit) {
      dispatch(promotions.fetch(null, {}))
    }
  }

  function handleSuccess(options, bonusOffer) {
    dispatch([
      isDeposit &&
        options.container !== 'brite' &&
        player.fetch({ success: Analytics.deposit }),
      handleStepChange(
        TransactionStep.TransactionSuccess,
        {
          bonusOffer,
        },
        config.rememberDepositAmount,
        {
          depositNumber: config.depositNumber,
        }
      ),
    ])
  }

  function handleClose() {
    dispatch([
      ui.toggleVisibility(props.transactionType, false),
      ui.toggleVisibility('cancelPendingWithdrawals', true),
      query.depositSuccessRedirectUrl &&
        redirectTo(query.depositSuccessRedirectUrl),
    ])
  }

  if (R.equals(config.clientType, clientTypes.UNKNOWN)) {
    return null
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <Transaction
      dataSchema={dataSchema}
      depositCount={config.depositCount}
      fieldBuilderInitialValues={fieldBuilderInitialValues}
      license={config.license}
      payerInitialValues={payerInitialValues}
      showPendingTransactions={config.showPendingTransactions}
      transactionType={props.transactionType}
      welcomeOfferMinDeposit={config.welcomeOfferMinDeposit}
      minAmount={minAmount}
      onClose={handleClose}
      onClaimOffer={handleClaimOffer}
      onClaimCampaign={handleClaimCampaign}
      onCleanup={handleCleanup}
      onInit={handleInit}
      onStepChange={handleStepChange}
      onSuccess={handleSuccess}
    />
  )
}

TransactionContainer.propTypes = {
  transactionType: PropTypes.oneOf([
    Constants.TransactionType.DEPOSIT,
    Constants.TransactionType.WITHDRAWAL,
  ]),
}
