import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

const Input = styled.input`
  display: none;
`

const Label = styled.label`
  ${props => (props.disabled ? `cursor: not-allowed;` : `cursor: pointer;`)};
`
export function Checkbox(props) {
  return (
    <Label disabled={props.disabled}>
      <Input
        type="checkbox"
        disabled={props.disabled}
        checked={props.checked}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
      />
      {props.children}
    </Label>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}
