import * as R from 'ramda'
import * as recompose from 'recompose'
import PropTypes from 'prop-types'

import * as Constants from '../../constants'

import { DepositLimitInformation } from './deposit-limit-info'
import { WithdrawalLimitInfo } from './withdrawal-limit-info'

export const connector = R.compose(
  recompose.setPropTypes({
    transactionType: PropTypes.oneOf([
      Constants.TransactionType.DEPOSIT,
      Constants.TransactionType.WITHDRAWAL,
    ]),
  }),
  recompose.branch(
    props => props.transactionType === Constants.TransactionType.DEPOSIT,
    recompose.renderComponent(DepositLimitInformation)
  ),
  recompose.branch(
    props => props.transactionType === Constants.TransactionType.WITHDRAWAL,
    recompose.renderComponent(WithdrawalLimitInfo)
  )
)

export const TransactionLimitInfo = connector(() => null)
