import styled from '@emotion/styled'
import { css } from '@styled-system/css'

export const TopbarCta = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
  lain-height: 20px;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px rgba(204, 68, 0, 0.5);
  ${css({
    color: 'simpleorange',
    fontSize: 1,
    fontWeight: 700,
    backgroundColor: 'white',
    pt: '2px',
    px: 1,
  })}
  &:hover {
    ${props =>
      css({
        border: props.clickable && '1px solid',
        borderColor: props.clickable && 'simpleorange',
      })}
  }
  &:active {
    ${props =>
      css({
        boxShadow: props.clickable && 'inset 0px 3px 4px rgba(204, 68, 0, 0.5)',
      })}
  }
`
