import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { differenceInMinutes } from 'date-fns'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as RealityCheck from '@rushplay/compliance/reality-check'
import styled from '@emotion/styled'

import { Card } from '../components/common/card'
import { H2, H3 } from '../components/common/headings'
import { PrimaryButton } from '../components/common/button'
import { clearSessionStats, fetchSessionStats } from '../player/actions'
import { getSessionStats } from '../player/selectors'

const PopupWrapper = styled(Modal)`
  outline: none;
  margin: ${t.spacing(0)};
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding: 8px;
`
const Divider = styled.hr`
  margin-top: ${t.spacing(1)};
  margin-bottom: ${t.spacing(1)};
  width: 100%;
  border-top: 1px solid ${t.color('gray')};
`

function RealityCheckPopupContainer(props) {
  const i18n = I18n.useI18n()
  const [checked, setChecked] = React.useState(false)
  const dispatch = ReactRedux.useDispatch()

  const sessionStats = ReactRedux.useSelector(state =>
    getSessionStats(state.player)
  )
  const loggedInAt = ReactRedux.useSelector(state =>
    R.path(['loggedInAt'], state.session)
  )
  const period = ReactRedux.useSelector(state =>
    RealityCheck.getRealityCheckPeriod(state.realityCheck)
  )

  React.useEffect(() => {
    dispatch(fetchSessionStats())
    return () => {
      dispatch(clearSessionStats())
    }
  }, [])

  if (props.parentNode) {
    return (
      <PopupWrapper
        ariaHideApp={false}
        contentLabel="contentLabel"
        isOpen
        parentSelector={() => props.parentNode}
      >
        <Common.Box maxWidth="500px">
          <Card>
            <Common.Space p={1}>
              <H2 align="center">{i18n.translate('reality-check.reminder')}</H2>
              <Common.Space p={t.spacing(0)}>
                <Common.Flex flexDirection="column" alignItems="center">
                  <Common.Text lineHeight="1.5" textAlign="center">
                    {i18n.translate('reality-check.playing-for', {
                      time: period / 60000,
                    })}
                    {sessionStats && (
                      <Common.Text lineHeight="1.5" textAlign="center">
                        <Divider />
                        <H3 align="center">
                          {i18n.translate('reality-check.session-stats')}
                        </H3>
                        <p>
                          {i18n.translate(
                            sessionStats.gamingBalanceCents < 0
                              ? 'reality-check.session-balance-negative'
                              : 'reality-check.session-balance-positive',
                            {
                              balance: sessionStats.gamingBalanceCents,
                            }
                          )}
                        </p>
                        <p>
                          <Common.Timestamp>
                            {timestamp =>
                              i18n.translate(
                                'reality-check.session-logged-in',
                                {
                                  minutes: differenceInMinutes(
                                    timestamp,
                                    loggedInAt
                                  ),
                                }
                              )
                            }
                          </Common.Timestamp>
                        </p>
                      </Common.Text>
                    )}
                  </Common.Text>
                </Common.Flex>
              </Common.Space>
              <Common.Box display="flex" py="0" maxWidth="300px">
                <input
                  type="checkbox"
                  value={checked}
                  onChange={() => setChecked(current => !current)}
                />
                <Common.Box pl="0" lineHeight="14px">
                  {i18n.translate('reality-check.acknowledge')}
                </Common.Box>
              </Common.Box>
              <Common.Box maxWidth="200px" margin="auto">
                <StyledPrimaryButton
                  onClick={props.onDismiss}
                  disabled={!checked}
                  stretched
                >
                  {i18n.translate('reality-check.continue')}
                </StyledPrimaryButton>
                <Common.Space py="1">
                  <StyledPrimaryButton as={Common.Link} to="/casino" stretched>
                    {i18n.translate('reality-check.leave-game')}
                  </StyledPrimaryButton>
                </Common.Space>
              </Common.Box>
              <Common.Flex justifyContent="center">
                <Common.Link to="/gambling-history">
                  {i18n.translate('reality-check.history')}
                </Common.Link>
              </Common.Flex>
            </Common.Space>
          </Card>
        </Common.Box>
      </PopupWrapper>
    )
  }

  return null
}

RealityCheckPopupContainer.propTypes = {
  onDismiss: PropTypes.func,
  parentNode: PropTypes.object,
}

export default RealityCheckPopupContainer
