import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'
import kebabCase from 'lodash.kebabcase'

import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import { H3 } from '../../components/common/headings'

import { BonusOfferCard } from './bonus-offer-card'

function getOfferKeys(offerPackage, maxDepositNumber) {
  const types = R.reject(
    // Reward can be either a string/number or an array comprised of null/value items
    reward =>
      R.isNil(offerPackage[reward]) ||
      (Array.isArray(offerPackage[reward]) &&
        R.isEmpty(R.reject(R.isNil, offerPackage[reward]))),
    [
      'bonusMoneyPercent',
      'fixedBonusMoney',
      'fixedFreespins',
      'fixedFeatureTriggers',
      'bonusRubies',
    ]
  )

  return R.map(
    type =>
      type === 'bonusMoneyPercent' && maxDepositNumber > 1
        ? 'wallet.deposit-offer.reload-bonus-money-percent'
        : `wallet.deposit-offer.${kebabCase(type)}`,
    types
  )
}

export function BonusOffer(props) {
  const i18n = useI18n()
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  const amountCents = Forms.useField('#/properties/amount', {
    noRegister: true,
  })

  return (
    <Common.Space>
      <H3 color="black" style={{ fontSize: '18px' }} align="center">
        {i18n.translate('select-bonus-offer')}
      </H3>
      {R.map(
        offer =>
          R.map(offerPackage => {
            return (
              <Common.Space pt="0" key={offerPackage.id}>
                <BonusOfferCard
                  disabled={amountCents < offerPackage.rangeLimits[0]}
                  id={offerPackage.id}
                  selected={field.value === offerPackage.id}
                  translationKeys={getOfferKeys(
                    offerPackage,
                    offer.maxDepositNumber
                  )}
                  translationVariables={{
                    bonusMoneyPercent: offerPackage.bonusMoneyPercent,
                    bonusMoneyPercentMaxCents:
                      offerPackage.bonusMoneyPercentMaxCents,
                    maxBonusRubies:
                      offerPackage.bonusRubies[
                        offerPackage.bonusRubies.length - 1
                      ],
                    maxFixedBonusMoney:
                      offerPackage.fixedBonusMoney[
                        offerPackage.fixedBonusMoney.length - 1
                      ],
                    maxFixedFeatureTriggers:
                      offerPackage.fixedFeatureTriggers[
                        offerPackage.fixedFeatureTriggers.length - 1
                      ],
                    maxFixedFreespins:
                      offerPackage.fixedFreespins[
                        offerPackage.fixedFreespins.length - 1
                      ],
                  }}
                  value={offerPackage.id}
                  scope={props.scope}
                  onChange={field.onChangeValue}
                />
              </Common.Space>
            )
          }, offer.packages),
        props.bonusOffers
      )}
      {amountCents < props.welcomeOfferMinDeposit && (
        <Common.Text
          color="orange"
          align="center"
          fontSize="0"
          lineHeight="1.5"
        >
          {i18n.translate('deposit.amount-too-low', {
            amount: props.welcomeOfferMinDeposit,
          })}
        </Common.Text>
      )}
    </Common.Space>
  )
}

BonusOffer.propTypes = {
  bonusOffers: PropTypes.array,
  initialValue: PropTypes.object,
  scope: PropTypes.string.isRequired,
  welcomeOfferMinDeposit: PropTypes.number,
}
