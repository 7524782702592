import PropTypes from 'prop-types'
import React from 'react'
import format from 'date-fns/format'

import { Timestamp } from '@rushplay/common'

export function Clock(props) {
  return <Timestamp>{timestamp => format(timestamp, props.format)}</Timestamp>
}

Clock.defaultProps = { format: 'HH:mm' }

Clock.propTypes = { format: PropTypes.string }
