import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as Url from 'url'
import * as ReduxEffects from 'redux-effects'
import * as TimerEffects from 'redux-effects-timeout'
import PropTypes from 'prop-types'
import querystring from 'querystring'
import { getTime } from 'date-fns'

import * as Forms from '@rushplay/forms'
import * as Analytics from '@rushplay/analytics'
import * as Common from '@rushplay/common'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Session from '@rushplay/session'

import * as Constants from './constants'
import * as Configuration from './configuration'
import * as CombinedSelectors from './combined-selectors'
import * as Player from './player'
import * as _Session from './session'
import * as SseHandler from './use-sse-listener'

export function getSearchQuery(location) {
  return querystring.parse(R.drop(1, location.search))
}

export function getPayerConfig(state) {
  return {
    clientType: Configuration.getClientType(state.configuration),
    host: Configuration.getPayerUrl(state.configuration),
    token: Session.getSessionToken(state.session),
    userId: Session.getUsername(state.session),
  }
}

function getInitialData(state) {
  return {
    affiliateClickId: Analytics.getClickId(state.analytics),
    affiliateSubId: Analytics.getSubId(state.analytics),
    city: _Session.getPlayerCity(state.session),
    email: _Session.getPlayerEmail(state.session),
    firstName: _Session.getPlayerFirstName(state.session),
    lastName: _Session.getPlayerLastName(state.session),
    netrefererBtag: Analytics.getBtag(state.analytics),
    phoneNumber: _Session.getPlayerPhoneNumber(state.session),
    postalCode: _Session.getPlayerZip(state.session),
    province: _Session.getPlayerState(state.session),
    ssn: _Session.getPersonalNumber(state.session),
    state:
      _Session.getPlayerState(state.session) ||
      _Session.getPlayerCity(state.session),
    street: _Session.getPlayerStreet(state.session),
    utmCampaign: Analytics.getUtmCampaign(state.analytics),
    utmMedium: Analytics.getUtmMedium(state.analytics),
    utmSource: Analytics.getUtmSource(state.analytics),
    verificationToken: Session.getSessionToken(state.session),
  }
}

function getServerConfig(state) {
  return {
    countryCode: CombinedSelectors.getCountryCode(state),
    currency: CombinedSelectors.getCurrency(state),
    language: CombinedSelectors.getLanguage(state),
    license: Jurisdiction.getLicense(state.jurisdiction),
  }
}

function reportDeposit(retry = 1) {
  if (retry >= 20) {
    // This action type is not used; defined for clarity
    return { type: 'payer/analytics/CANCEL_EVENT' }
  }

  return Player.fetch({
    success: res => {
      const currentDate = getTime(new Date())
      const lastDepositTime = getTime(
        R.path(
          ['lastSuccessfullBriteDepositTransactionUpdatedAt'],
          res.value.result
        )
      )

      if (currentDate - lastDepositTime >= 2 * 60 * 1000) {
        return ReduxEffects.bind(
          TimerEffects.timeout(() => reportDeposit(retry + 1), 1000 * retry),
          // This action type is not used; defined for clarity
          () => ({ type: 'payer/analytics/RETRY_EVENT' })
        )
      }
      return Analytics.deposit()
    },
    failure: () =>
      ReduxEffects.bind(
        TimerEffects.timeout(() => reportDeposit(retry + 1), 1000 * retry),
        // This action type is not used; defined for clarity
        () => ({ type: 'payer/analytics/RETRY_EVENT' })
      ),
    silent: true,
  })
}

export function Payer(props) {
  const ref = React.useRef(null)
  const dispatch = ReactRedux.useDispatch()
  const serverConfig = ReactRedux.useSelector(getServerConfig)
  const payerConfig = ReactRedux.useSelector(getPayerConfig)
  const initialData = ReactRedux.useSelector(getInitialData)
  const { subscribe } = SseHandler.useContext()
  const amountCents = Forms.useField('#/properties/amount', {
    noRegister: true,
  }).value
  const location = ReactRouter.useLocation()
  const searchQuery = getSearchQuery(location)

  const payerUrl = Url.format({
    pathname: props.transactionType,
    query: {
      amount_cents: amountCents,
      client_type: payerConfig.clientType,
      country_code: serverConfig.countryCode,
      currency: serverConfig.currency,
      language: serverConfig.language,
      license: serverConfig.license,
      offer_id: props.offerId,
      token: payerConfig.token,
      user_id: payerConfig.userId,
      zimpler: searchQuery.zimpler || 0,
    },
  })

  React.useEffect(() => {
    function payerEventListener(event) {
      const action = event.data || {}

      switch (action.type) {
        case 'payer/INITIATED': {
          const postData = R.reject(
            item => R.isEmpty(item) || R.isNil(item),
            R.mergeDeepRight(props.initialData, initialData)
          )

          if (!R.isEmpty(postData)) {
            ref.current.contentWindow.postMessage(
              {
                type: 'payer/UPDATE_INFORMATION',
                payload: postData,
              },
              '*'
            )
          }
          break
        }

        case 'payer/STEP_CHANGED': {
          props.onStepChange && props.onStepChange(action.payload)
          break
        }

        case 'payer/TRANSACTION_CANCELED': {
          props.onCancel && props.onCancel(action.payload)
          break
        }

        case 'payer/TRANSACTION_STARTED': {
          const { url } = action.payload
          subscribe({
            url,
            callback: e => {
              const data = JSON.parse(e.data)
              if (data.type === 'payer/TRANSACTION_SUCCEEDED') {
                dispatch(reportDeposit())
              }
            },
          })
          break
        }

        case 'payer/TRANSACTION_FAILED': {
          props.onFailure && props.onFailure(action.payload)
          break
        }

        case 'payer/TRANSACTION_SUCCEEDED': {
          props.onSuccess && props.onSuccess(action.payload)
          break
        }

        default: {
          return null
        }
      }
    }
    window.addEventListener('message', payerEventListener)
    return () => window.removeEventListener('message', payerEventListener)
  }, [])

  return (
    <Common.Box
      as="iframe"
      borderRadius={1}
      height="100%"
      ref={ref}
      sandbox={R.join(' ', [
        'allow-forms',
        'allow-modals',
        'allow-orientation-lock',
        'allow-popups',
        'allow-popups-to-escape-sandbox',
        'allow-presentation',
        'allow-same-origin',
        'allow-scripts',
        'allow-top-navigation',
        'allow-top-navigation-by-user-activation',
      ])}
      src={`${payerConfig.host}/${payerUrl}`}
      title="Wallet"
      width="100%"
    />
  )
}

Payer.propTypes = {
  initialData: PropTypes.object,
  offerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transactionType: PropTypes.oneOf([
    Constants.TransactionType.AUTH,
    Constants.TransactionType.DEPOSIT,
    Constants.TransactionType.WITHDRAWAL,
  ]),
  onCancel: PropTypes.func,
  onFailure: PropTypes.func,
  onStepChange: PropTypes.func,
  onSuccess: PropTypes.func,
}
