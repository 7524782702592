import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Url from 'url'

import * as Session from '@rushplay/session'

import * as CombinedSelectors from './combined-selectors'
import * as Configuration from './configuration'

function getPayerConfig(state) {
  return {
    client_type: Configuration.getClientType(state.configuration),
    country_code: CombinedSelectors.getCountryCode(state),
    host: Configuration.getPayerUrl(state.configuration),
    token: Session.getSessionToken(state.session),
    userId: Session.getUsername(state.session),
  }
}

export function useTransactionAmountLimits(transactionType) {
  const [limits, setLimits] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const payerConfig = ReactRedux.useSelector(getPayerConfig)

  const payerUrl = Url.format({
    pathname: '/api/transaction-limits',
    query: {
      transaction_type: transactionType,
      client_type: payerConfig.clientType,
      country_code: payerConfig.country_code,
      token: payerConfig.token,
      user_id: payerConfig.userId,
    },
  })

  React.useEffect(() => {
    const controller = new AbortController()
    fetch(`${payerConfig.host}${payerUrl}`, {
      method: 'GET',
      signal: controller.signal,
    })
      .then(res => res.json())
      .then(body => {
        setLimits(body.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })

    const id = setTimeout(() => controller.abort(), 5000)

    return () => {
      controller.abort()
      clearTimeout(id)
    }
  }, [payerConfig.host, payerUrl])

  return { limits, loading }
}
