import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import { Campaign } from './campaign'

export function Campaigns(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return (
    <Common.Box pt={1}>
      <Common.Box
        color="black"
        fontSize="18px"
        lineHeight="1.45"
        fontWeight="bold"
        textAlign="center"
      >
        {i18n.translate('select-campaign')}
      </Common.Box>
      {R.map(
        promotion => (
          <Common.Space pt="0" key={promotion.key}>
            <Campaign
              campaign={promotion.campaign}
              promotionKey={promotion.key}
              selected={field.value === promotion.campaign.id}
              scope={props.scope}
              value={promotion.campaign.id}
              onChange={field.onChangeValue}
            />
          </Common.Space>
        ),
        R.values(props.promotions)
      )}
    </Common.Box>
  )
}

Campaigns.propTypes = {
  initialValue: PropTypes.number,
  promotions: PropTypes.object,
  scope: PropTypes.string.isRequired,
}
