import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as T from '@rushplay/theme'
import styled from '@emotion/styled'

const CampaignWrapper = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  color: rgba(17, 46, 64, 1);
  background-color: rgba(255, 255, 255, 1);
  padding: ${T.spacing(1)};
  margin-bottom: ${T.spacing(2)};
`

const TermsConditions = styled.a`
  text-decoration: underline;
  font-size: 12px;
  line-height: normal;
  font-family: ${T.color('darkgray')};
  font-family: ${T.font('head')};
`

export function CampaignDisclaimer(props) {
  const i18n = I18n.useI18n()

  Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return (
    <Common.Box pt={1}>
      <Common.Box
        color="darkgray"
        fontSize="18px"
        lineHeight="32px"
        fontWeight="600"
        textAlign="center"
        pb="0"
      >
        {i18n.translate('wallet.campaigns.selected-campaign-disclaimer')}
      </Common.Box>
      <CampaignWrapper>
        <Common.Space pb="10px">
          <Common.Text
            fontSize="16px"
            fontWeight="bold"
            lineHeight="24px"
            color="#112d40"
          >
            {i18n.translate(`promotions.${props.selectedPromotion.key}.title`)}
          </Common.Text>
        </Common.Space>
        <TermsConditions
          href={`/promotion/${props.selectedPromotion.key}`}
          target="blank"
          rel="noreferrer noopener"
        >
          {i18n.translate('wallet.campaigns.terms-conditions-link')}
        </TermsConditions>
      </CampaignWrapper>
    </Common.Box>
  )
}

CampaignDisclaimer.propTypes = {
  initialValue: PropTypes.number,
  selectedPromotion: PropTypes.object,
  value: PropTypes.string,
  scope: PropTypes.string.isRequired,
}
