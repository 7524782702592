import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as T from '@rushplay/theme'
import styled from '@emotion/styled'

import { Checkbox } from '../checkbox'

export const MarkerIcon = styled.div`
  width: 20px;
  height: 20px;
  mask: url(/images/marker-icon.svg) no-repeat center;
  background-color: white;
`

const CampaignWrapper = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  color: rgba(17, 46, 64, 1);
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: space-between;
  padding: ${T.spacing(0)};
`

const TermsConditions = styled.a`
  padding-top: 5px;
  text-decoration: underline;
  font-size: 12px;
  line-height: normal;
  font-family: ${T.font('head')};
`

export function Campaign(props) {
  const i18n = I18n.useI18n()
  const selectedOffer = Forms.useField(props.scope, { noRegister: true })

  return (
    <Checkbox
      checked={props.selected}
      value={props.value}
      onChange={() => {
        if (selectedOffer.value === props.value) {
          props.onChange(undefined)
        } else {
          props.onChange(props.value)
        }
      }}
    >
      <CampaignWrapper>
        <Common.Box paddingRight={0} maxWidth="240px">
          <Common.Text
            fontSize="16px"
            fontWeight="bold"
            lineHeight="normal"
            color="#112d40"
          >
            {i18n.translate(`promotions.${props.promotionKey}.title`)}
          </Common.Text>
          <TermsConditions
            href={`/promotion/${props.promotionKey}`}
            target="blank"
            rel="noreferrer noopener"
          >
            {i18n.translate('wallet.campaigns.terms-conditions-link')}
          </TermsConditions>
        </Common.Box>
        <Common.Box my="auto">
          <Common.Image
            src={
              props.selected
                ? '/images/deposit-offer-checkbox-selected.svg'
                : '/images/deposit-offer-checkbox-unselected.svg'
            }
          />
        </Common.Box>
      </CampaignWrapper>
    </Checkbox>
  )
}

Campaign.propTypes = {
  promotionKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  scope: PropTypes.string.isRequired,
  value: PropTypes.number,
}
