import PropTypes from 'prop-types'
import React from 'react'

import * as icons from '@rushplay/icons'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { Flex, Link, Space } from '@rushplay/common'

import { Clock } from '../../components/clock'
import { Fullscreen } from '../../components/fullscreen'
import { RealityCheck } from '../reality-check'
import { Wallet } from '../../wallet'

const StyledFullscreen = styled(Fullscreen)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  // 60px is the topbar height
  min-height: calc(100vh - 60px);
`

const FullscreenButton = styled.div`
  cursor: pointer;
  color: ${t.color('gray')};

  &:hover,
  &:active {
    color: ${t.color('white')};
  }
`

const GameBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${t.color('black')};
  border-bottom: 1px solid ${t.color('primary')};
  padding: ${t.spacing(0)};
  font-family: ${t.font('head')};
  color: white;
  height: 46px;
`

/**
 * {shouldForwardProp} is an un-documentet function in react-emotion that's basicly a function that tells what props to pass forward
 * Doing this thing gets rid of any custom prop that the styled(Component) does not support. As in this case; active.
 * Which is only used to change the color of the text. This then won't give you a console warning saying this prop is not supported.
 **/
const ModeLink = styled(Link, {
  shouldForwardProp: propName => propName !== 'active',
})`
  color: ${t.color('gray')};
  ${props => props.active && 'color: white;'};

  &:hover {
    color: white;
  }
`

const ModeSelector = styled.div`
  color: ${t.color('gray')};
  padding-right: ${t.spacing(1)};
  user-select: none;
  display: flex;
`

//TODO: use an icon from @rushplay/icons instead of png
const Deposit = styled(Space)`
  margin-right: 8px;
  margin-left: 8px;
  height: 30px;
  width: 30px;
  background-image: url(/images/wallet.png);
  background-size: cover;
  cursor: pointer;
`

const ClockWrapper = styled.div`
  padding-top: 3px;
  padding-right: 5px;
`

export function GameWrapper(props) {
  return (
    <StyledFullscreen key={props.mode}>
      {({ isFullscreen, onToggle }) => (
        <React.Fragment>
          <Wallet forceRender />
          <Flex wide flexDirection="column" flexShrink={0} flexGrow={0}>
            {props.showGameBar && (
              <GameBar>
                <Flex>
                  <ClockWrapper>
                    <Clock />
                  </ClockWrapper>
                  {props.isEmbedded && (
                    <Link to="/casino">
                      <FullscreenButton>
                        <icons.IconWrapper size="16px">
                          <icons.IconHome />
                        </icons.IconWrapper>
                      </FullscreenButton>
                    </Link>
                  )}
                </Flex>
                <Flex alignItems="center">
                  {props.showModeSelector && (
                    <ModeSelector>
                      <Space paddingRight="0">
                        {props.translate('game.game-bar.mode')}
                      </Space>
                      <ModeLink
                        active={props.mode === 'blitz'}
                        to={`${props.location.pathname}?referrer=casino&mode=blitz`}
                      >
                        {props.translate('game.game-bar.blitz')}
                      </ModeLink>
                      <Space px="0">|</Space>
                      <ModeLink
                        active={props.mode !== 'blitz'}
                        to={`${props.location.pathname}?referrer=casino&mode=classic`}
                      >
                        {props.translate('game.game-bar.classic')}
                      </ModeLink>
                    </ModeSelector>
                  )}
                  {props.authenticated && (
                    <Deposit right="0" onClick={props.onShowDeposit} />
                  )}
                  {props.showFullscreenToggle && (
                    <FullscreenButton onClick={() => onToggle(!isFullscreen)}>
                      {isFullscreen ? (
                        <icons.IconWrapper size="16px">
                          <icons.IconTimes />
                        </icons.IconWrapper>
                      ) : (
                        <icons.IconWrapper size="22px">
                          <icons.IconFullscreen />
                        </icons.IconWrapper>
                      )}
                    </FullscreenButton>
                  )}
                </Flex>
              </GameBar>
            )}
          </Flex>
          <RealityCheck iframeRef={props.iframeRef} />
          {props.children}
        </React.Fragment>
      )}
    </StyledFullscreen>
  )
}

GameWrapper.propTypes = {
  authenticated: PropTypes.bool,
  children: PropTypes.node,
  iframeRef: PropTypes.object.isRequired,
  isEmbedded: PropTypes.bool,
  location: PropTypes.object.isRequired,
  mode: PropTypes.string,
  showFullscreenToggle: PropTypes.bool,
  showGameBar: PropTypes.bool,
  showModeSelector: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  onModeChange: PropTypes.func.isRequired,
  onShowDeposit: PropTypes.func.isRequired,
}
