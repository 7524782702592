import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import * as session from '@rushplay/session'
import * as realityCheck from '@rushplay/compliance/reality-check'
import { withTranslate } from '@rushplay/i18n'

import * as configuration from '../../configuration'

import { RealityCheck as Component } from './reality-check'

function mapStateToProps(state) {
  return {
    gameServerUrl: configuration.getGameServerUrl(state.configuration),
    sessionToken: session.getSessionToken(state.session),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onFetch: () => dispatch(realityCheck.fetch()),
  }
}

const connector = R.compose(
  withTranslate,
  connect(mapStateToProps, mapDispatchToProps),
  recompose.branch(props => !props.sessionToken, recompose.renderNothing)
)

export const RealityCheck = connector(Component)
