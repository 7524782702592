import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import * as Icons from '../components/icons.js'
import { Button, PrimaryButton } from '../components/common/button'

const mapIndexed = R.addIndex(R.map)
const SECTIONS = [
  { type: 'decision' },
  {
    type: 'secure',
    Icon: Icons.Lock,
  },
  {
    type: 'support',
    Icon: Icons.Help,
  },
]

export function PreWithdrawalScreen(props) {
  const i18n = useI18n()

  return (
    <Common.Box
      textAlign="center"
      fontWeight="bold"
      fontSize="1"
      mb={[null, '70px', '0px']}
    >
      {mapIndexed(
        (section, i) => (
          <React.Fragment key={section.type}>
            <Common.Box fontSize={i == 0 ? 3 : 2} color="black">
              {i18n.translate(`wallet.pre-withdrawal.${section.type}.title`)}
            </Common.Box>

            {section.Icon && (
              <section.Icon size="60px" color="primary" mt="1" />
            )}

            <Common.Box mt="1" mb="3">
              {i18n.translate(
                `wallet.pre-withdrawal.${section.type}.description`
              )}
            </Common.Box>
          </React.Fragment>
        ),
        SECTIONS
      )}

      <Common.Box
        as={PrimaryButton}
        onClick={props.onContinueToWithdrawal}
        width="100%"
        mt="2"
        mb="1"
      >
        {i18n.translate(`wallet.pre-withdrawal.button.continue`)}
      </Common.Box>
      <Button onClick={props.onCloseWallet} stretched>
        {i18n.translate(`wallet.pre-withdrawal.button.close`)}
      </Button>
    </Common.Box>
  )
}

PreWithdrawalScreen.propTypes = {
  onCloseWallet: PropTypes.func,
  onContinueToWithdrawal: PropTypes.func,
}
