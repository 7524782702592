import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import * as t from '@rushplay/theme'
import * as Icons from '@rushplay/icons'
import * as Common from '@rushplay/common'
import * as Session from '@rushplay/session'
import styled from '@emotion/styled'

import * as Analytics from '../analytics'
import * as Constants from '../constants'
import * as CombinedSelectors from '../combined-selectors'
import * as Player from '../player'
import * as Ui from '../ui'
import * as Utils from '../utils'
import { Amount } from '../components/common/amount'
import { Close } from '../components/icons'
import { Fade } from '../components/common/fade'
import { IconCircle } from '../components/common/icon-circle'
import { Logotype } from '../components/common/logotype'
import { Spinner } from '../components/common/spinner'
import { TopbarCta } from '../components/topbar/simple-topbar-cta'
import { usePendingTransactions } from '../use-pending-transactions'

import { PendingWithdrawalsScreen } from './pending-withdrawals-screen'
import { PreWithdrawalScreen } from './pre-withdrawals-screen'
import { TransactionContainer } from './transaction-container'

function useAnalyticsOpenWallet() {
  const location = ReactRouter.useLocation()
  const dispatch = ReactRedux.useDispatch()
  const sideMenuOpen = ReactRedux.useSelector(state =>
    Ui.getVisibility(state.ui, { id: 'sidebar' })
  )
  const walletOpen = ReactRedux.useSelector(state =>
    Ui.getVisibility(state.ui, { id: Constants.TransactionType.DEPOSIT })
  )
  const prevSideMenuOpen = Utils.Hooks.usePreviousValue(sideMenuOpen)
  const prevLocation = Utils.Hooks.usePreviousValue(location)
  const prevWalletOpen = Utils.Hooks.usePreviousValue(walletOpen)

  React.useEffect(() => {
    if (walletOpen && !prevWalletOpen) {
      dispatch(
        Analytics.openWallet({
          path: prevLocation
            ? `${prevLocation.pathname}${prevLocation.search}${prevLocation.hash}`
            : '/',
          title: document.title,
          menuActive: prevSideMenuOpen,
        })
      )
    }
  }, [
    dispatch,
    sideMenuOpen,
    walletOpen,
    prevWalletOpen,
    prevLocation,
    prevSideMenuOpen,
  ])
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(17, 45, 64, 0.6);
`

const Sidebar = styled.aside`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 130;
  height: 100vh;
  width: 100%;
  background-color: ${t.color('storePageBackground')};
  opacity: ${props => (props.active ? '100%' : '0%')};
  transform: translateX(${props => (props.active ? '0%' : '100%')});
  transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  font-size: ${t.fontSize(1)};
  padding-bottom: ${t.spacing(4)};

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${t.color('primary')};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${t.color('gray9')};
    border-radius: 10px;
  }

  ${t.mediaQuery.sm`
    width: 85vw;
    max-width: 350px;
  `};
`

const StyledIconCircle = styled(IconCircle)`
  color: #61696b;
  box-shadow: ${t.boxShadow(1)};
  &:hover {
    border: 1px solid black;
    cursor: pointer;
  }
`

const CountUp = Common.withCountUp(Amount, {
  propName: 'children',
  factor: 10,
})

const WalletSteps = {
  PendingTransactions: 'PENDING_TRANSACTIONS',
  PreWithdrawal: 'PRE_WITHDRAWAL',
  Transaction: 'TRANSACTION',
}

function getBalanceConfig(state) {
  return {
    balanceCents: Session.getBalanceCents(state.session),
    shouldShowCountUp: false,
  }
}

function getWalletConfig(state) {
  const transactionType = Ui.getTransactionType(state.ui)
  const isDeposit = transactionType === Constants.TransactionType.DEPOSIT
  const isCancelPendingWithdrawalsVisible = CombinedSelectors.isCancelPendingWithdrawalsVisible(
    state
  )

  return {
    authenticated: Session.isSessionActive(state.session),
    transactionType: Ui.getTransactionType(state.ui),
    showPendingWithdrawals: isDeposit && isCancelPendingWithdrawalsVisible,
    isWalletOpen: !R.isNil(transactionType),
  }
}

function Wallet(props) {
  const dispatch = ReactRedux.useDispatch()
  const config = ReactRedux.useSelector(getWalletConfig)
  const balanceConfig = ReactRedux.useSelector(getBalanceConfig)
  const [step, setStep] = React.useState(null)
  useAnalyticsOpenWallet()

  function handleClose() {
    dispatch([
      Ui.toggleVisibility(config.transactionType, false),
      Ui.toggleVisibility('cancelPendingWithdrawals', true),
    ])
  }

  React.useEffect(() => {
    if (config.isWalletOpen) {
      if (
        config.showPendingWithdrawals &&
        !R.isEmpty(props.pendingTransactions)
      ) {
        return setStep(WalletSteps.PendingTransactions)
      } else {
        return setStep(WalletSteps.Transaction)
      }
    } else {
      setStep(null)
    }
  }, [
    config.isWalletOpen,
    config.showPendingWithdrawals,
    props.pendingTransactions,
  ])

  React.useEffect(() => {
    if (R.not(config.authenticated)) {
      // handle close wallet
      handleClose()
    }

    dispatch(Ui.toggleVisibility('cancelPendingWithdrawals', true))
  }, [config.authenticated])

  return (
    <React.Fragment>
      <Fade in={config.isWalletOpen}>
        <Overlay onClick={handleClose} />
      </Fade>
      <Sidebar active={config.isWalletOpen}>
        <Common.Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          py="14px"
          px="1"
          mb="3"
          backgroundColor="white"
        >
          <Logotype height="41" justifySelf="start" variant="dark" />
          <Common.Box display="flex" justifySelf="end">
            <TopbarCta style={{ cursor: 'default' }}>
              <CountUp>{balanceConfig.balanceCents}</CountUp>
            </TopbarCta>
            <Common.Space pl="1">
              <StyledIconCircle
                onClick={handleClose}
                size="36"
                hoverColor="black"
              >
                <Icons.IconWrapper size="16px">
                  <Close />
                </Icons.IconWrapper>
              </StyledIconCircle>
            </Common.Space>
          </Common.Box>
        </Common.Box>
        {config.isWalletOpen && (
          <Common.Box px="1" height="100%">
            {step === WalletSteps.PendingTransactions && (
              <PendingWithdrawalsScreen
                onContinueToDeposit={() =>
                  dispatch(
                    Ui.toggleVisibility('cancelPendingWithdrawals', false)
                  )
                }
              />
            )}

            {step === WalletSteps.PreWithdrawal && (
              <PreWithdrawalScreen
                onContinueToWithdrawal={() => setStep(WalletSteps.Transaction)}
                onCloseWallet={handleClose}
              />
            )}

            {step === WalletSteps.Transaction && (
              <TransactionContainer transactionType={config.transactionType} />
            )}
          </Common.Box>
        )}
      </Sidebar>
    </React.Fragment>
  )
}

Wallet.propTypes = {
  pendingTransactions: PropTypes.array,
}

export function WalletContainer(props) {
  const { pendingTransactions, loading } = usePendingTransactions()
  const isGameView = ReactRedux.useSelector(state =>
    Ui.getVisibility(state.ui, { id: 'game-view' })
  )
  const dispatch = ReactRedux.useDispatch()
  const transactionType = ReactRedux.useSelector(state =>
    Ui.getTransactionType(state.ui)
  )

  React.useEffect(() => {
    if (transactionType === Constants.TransactionType.DEPOSIT) {
      dispatch(Player.fetchDepositInformation())
    }

    if (transactionType === Constants.TransactionType.WITHDRAWAL) {
      dispatch(Player.fetchWithdrawInformation())
    }
  }, [transactionType, dispatch])

  if (isGameView && !props.forceRender) {
    return null
  }

  if (loading) {
    return <Spinner />
  }

  return <Wallet pendingTransactions={pendingTransactions} />
}

WalletContainer.propTypes = {
  forceRender: PropTypes.bool,
}
