import PropTypes from 'prop-types'
import React from 'react'

import * as common from '@rushplay/common'

export function Fullscreen(props) {
  return (
    <common.Fullscreen
      className={props.className}
      enabled={props.isFullscreen}
      onChange={props.onToggle}
    >
      {props.children({
        isFullscreen: props.isFullscreen,
        onToggle: props.onToggle,
      })}
    </common.Fullscreen>
  )
}

Fullscreen.defaultProps = {
  isFullscreen: false,
}

Fullscreen.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  isFullscreen: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
}
