import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import { HtmlContent } from '../../../components/common/html-content'

export function WithdrawalLimitInfo(props) {
  const i18n = I18n.useI18n()

  return (
    <Common.Space pt="1" pb="4px">
      <Common.Text align="center" fontSize="14px" fontWeight="500">
        {!props.sportsbookEnabled && props.bonusBalanceCents > 0 && (
          <Common.Space paddingBottom={1}>
            <HtmlContent
              html={{
                __html: i18n.translate('cashout.bonus-disclaimer', {
                  bonusAmount: props.bonusBalanceCents,
                  moneyAmount: props.moneyBalanceCents,
                  wagerBalance: props.wagerBalanceCents,
                }),
              }}
            />
          </Common.Space>
        )}
        {props.sportsbookEnabled && props.bonusBalanceCents > 0 && (
          <Common.Space paddingBottom={1}>
            <HtmlContent
              html={{
                __html: i18n.translate('cashout.bonus-sports-disclaimer', {
                  bonusAmount: props.bonusBalanceCents,
                  moneyAmount: props.moneyBalanceCents,
                  sportWager: props.sportWagerBalanceCents,
                  wagerBalance: props.wagerBalanceCents,
                }),
              }}
            />
          </Common.Space>
        )}
        {props.amount > props.limitRemainder && (
          <Common.Space paddingBottom={1}>
            <HtmlContent
              html={{
                __html: i18n.translate('cashout.withdrawal-limit', {
                  limitRemainder: props.limitRemainder,
                  limit: props.limit,
                }),
              }}
            />
          </Common.Space>
        )}
        {props.amount < props.minAmount && (
          <HtmlContent
            html={{
              __html: i18n.translate('cashout.information', {
                minAmount: props.minAmount,
              }),
            }}
          />
        )}
      </Common.Text>
    </Common.Space>
  )
}

WithdrawalLimitInfo.propTypes = {
  amount: PropTypes.number,
  bonusBalanceCents: PropTypes.number,
  limit: PropTypes.number,
  limitRemainder: PropTypes.number,
  minAmount: PropTypes.number,
  moneyBalanceCents: PropTypes.number,
  sportsbookEnabled: PropTypes.bool.isRequired,
  sportWagerBalanceCents: PropTypes.number,
  wagerBalanceCents: PropTypes.number,
}
