import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as t from '@rushplay/theme'

import { Button } from '../components/common/button'
import { H3 } from '../components/common/headings'
import { PendingTransaction } from '../pending-transaction'
import { usePendingTransactions } from '../use-pending-transactions'

import { Spinner } from './spinner'

export function PendingWithdrawalsScreen(props) {
  const i18n = I18n.useI18n()
  const { pendingTransactions, loading } = usePendingTransactions()

  if (loading) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <Common.Space>
        <Common.Flex justifyContent="center">
          <H3 align="center" color="black" weight={800}>
            {i18n.translate('pending-withdrawals.title')}
          </H3>
        </Common.Flex>
      </Common.Space>
      <Common.Space pt={2} pb={1}>
        <Common.Flex justifyContent="center">
          <Common.Text
            textAlign="center"
            lineHeight="1.3"
            fontColor="gray"
            font="head"
            fontSize={1}
            fontWeight={600}
          >
            {i18n.translate('pending-withdrawals.instead-of-depositing')}
          </Common.Text>
        </Common.Flex>
      </Common.Space>
      {R.map(
        transaction => (
          <PendingTransaction
            amount={transaction.amount}
            date={transaction.created}
            key={transaction.transactionId}
            onCancel={() => transaction.onCancel(transaction.transactionId)}
          />
        ),
        pendingTransactions
      )}
      <Common.Space py={3}>
        <Common.Flex justifyContent="center" alignItems="center">
          <Common.Box
            borderBottom="1px solid"
            color={t.color('gray1')}
            width="100%"
          />
          <Common.Space px={1}>
            <Common.Text color={t.color('gray1')} font="head">
              {i18n.translate('pending-withdrawals.or')}
            </Common.Text>
          </Common.Space>
          <Common.Box
            borderBottom="1px solid"
            color={t.color('gray1')}
            width="100%"
          />
        </Common.Flex>
      </Common.Space>
      <Common.Space pt={2}>
        <Button onClick={props.onContinueToDeposit} stretched>
          {i18n.translate('pending-withdrawals.continue-to-deposit')}
        </Button>
      </Common.Space>
    </React.Fragment>
  )
}

PendingWithdrawalsScreen.propTypes = {
  onContinueToDeposit: PropTypes.func,
}
