import PropTypes from 'prop-types'
import React from 'react'

import * as icons from '@rushplay/icons'
import { Flex, Space, Text } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import { Card } from '../../../components/common/card'
import { HtmlContent } from '../../../components/common/html-content'

export function DepositLimitInformation(props) {
  const i18n = useI18n()

  if (!props.limit) {
    return null
  }

  return (
    <Space paddingTop="2">
      <Card backgroundColor="blue1">
        <Flex justifyContent="space-between">
          <icons.IconWrapper>
            <icons.IconInfo />
          </icons.IconWrapper>
          <Space paddingLeft={1}>
            <Text fontSize="0">
              <HtmlContent
                html={{
                  __html: i18n.translate(
                    `deposit.deposit-max-notification-${props.limit.interval}`,
                    {
                      leftToDeposit: props.limit.leftToDepositCents,
                      depositLimit: props.limit.limitCents,
                    }
                  ),
                }}
              />
            </Text>
            <Space paddingTop={0}>
              <Text
                size={0}
                decoration="underline"
                onClick={props.onChangeLimits}
                style={{ cursor: 'pointer' }}
              >
                {i18n.translate('deposit.change-limits')}
              </Text>
            </Space>
          </Space>
        </Flex>
      </Card>
    </Space>
  )
}

DepositLimitInformation.propTypes = {
  limit: PropTypes.object,
  onChangeLimits: PropTypes.func.isRequired,
}
