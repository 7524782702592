import * as R from 'ramda'
import React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as t from '@rushplay/theme'

import { H3 } from '../components/common/headings'
import { PendingTransaction } from '../pending-transaction'
import { Spinner } from '../components/common/spinner'
import { usePendingTransactions } from '../use-pending-transactions'

export function PendingTransactions() {
  const i18n = I18n.useI18n()
  const { pendingTransactions, loading } = usePendingTransactions()

  if (R.isEmpty(pendingTransactions) && !loading) {
    return null
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <H3 align="center" color="black" weight={800}>
        {i18n.translate('pending-withdrawals')}
      </H3>
      <Common.Space pt={1}>
        {R.map(
          transaction => (
            <PendingTransaction
              amount={transaction.amount}
              date={transaction.created}
              key={transaction.transactionId}
              onCancel={() => transaction.onCancel(transaction.transactionId)}
            />
          ),
          pendingTransactions
        )}
      </Common.Space>
      <Common.Space py={3}>
        <Common.Flex justifyContent="center" alignItems="center">
          <Common.Box
            borderBottom="1px solid"
            color={t.color('gray1')}
            width="100%"
          />
          <Common.Space px={1}>
            <Common.Text color={t.color('gray1')} font="head">
              {i18n.translate('pending-withdrawals.or')}
            </Common.Text>
          </Common.Space>
          <Common.Box
            borderBottom="1px solid"
            color={t.color('gray1')}
            width="100%"
          />
        </Common.Flex>
      </Common.Space>
    </React.Fragment>
  )
}
