import * as recompose from 'recompose'
import * as R from 'ramda'
import querystring from 'querystring'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as Constants from '../../constants'
import * as promotions from '../../promotions'
import * as ui from '../../ui'

import { CampaignDisclaimer } from './campaign-disclaimer'
import { Campaigns as Component } from './campaigns'

function mapStateToProps(state, props) {
  const query = querystring.parse(R.drop(1, props.location.search))
  const selectedCampaign = parseInt(query.campaign) || undefined
  const currentPromotions = promotions.getDepositCampaigns(state.promotions)

  return {
    initialValue: selectedCampaign,
    isDeposit: ui.getVisibility(state.ui, {
      id: Constants.TransactionType.DEPOSIT,
    }),
    promotions: currentPromotions,
    selectedPromotion: R.find(
      R.pathEq(['campaign', 'id'], selectedCampaign),
      R.values(currentPromotions)
    ),
  }
}

const connector = R.compose(
  withRouter,
  connect(mapStateToProps),
  recompose.branch(props => !props.isDeposit, recompose.renderNothing),
  recompose.branch(
    props => props.selectedPromotion,
    recompose.renderComponent(CampaignDisclaimer)
  ),
  recompose.branch(
    props => R.isEmpty(props.promotions),
    recompose.renderNothing
  )
)

export const Campaigns = connector(Component)
