import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Forms from '@rushplay/forms'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { Box, Image, Text } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import { Checkbox } from '../checkbox'

export const MarkerIcon = styled.div`
  width: 20px;
  height: 20px;
  mask: url(/images/marker-icon.svg) no-repeat center;
  background-color: white;
`

const BonusOffer = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  color: rgba(17, 46, 64, 1);
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: space-between;
  padding: ${t.spacing(0)};
  ${props =>
    props.disabled &&
    `box-shadow: none;
    filter:none;
    background-color: #7e838b;
  `};
`

const AmountWrapper = styled.div`
  margin: auto 0;
  padding-right: ${t.spacing(0)};
  font-size: 14px;
  max-width: 250px;
  ${props => props.disabled && `font-weight:600`};
`
const TermsConditions = styled.a`
  padding-top: 5px;
  text-decoration: underline;
  font-size: 12px;
  line-height: normal;
  font-family: ${t.font('head')};
  color: ${props => (props.disabled ? '#b2b5ba' : '#112d40')};
`

export function BonusOfferCard(props) {
  const i18n = useI18n()
  const selectedOffer = Forms.useField(props.scope, { noRegister: true })

  return (
    <Checkbox
      disabled={props.disabled}
      checked={props.selected}
      value={props.value}
      onChange={() => {
        if (selectedOffer.value === props.value) {
          props.onChange(undefined)
        } else {
          props.onChange(props.value)
        }
      }}
    >
      <BonusOffer disabled={props.disabled}>
        <AmountWrapper disabled={props.disabled}>
          <Text
            fontSize="16px"
            fontWeight="bold"
            lineHeight="normal"
            color={props.disabled ? '#b2b5ba' : '#112d40'}
          >
            {R.map(
              key => (
                <p key={key}>
                  {i18n.translate(key, props.translationVariables)}
                </p>
              ),
              props.translationKeys
            )}
          </Text>
          <TermsConditions
            disabled={props.disabled}
            href="/bonus/terms-conditions-bonus"
            target="blank"
            rel="noreferrer noopener"
          >
            {i18n.translate('wallet.deposit-offer.terms-conditions-link')}
          </TermsConditions>
        </AmountWrapper>
        {R.not(props.disabled) && (
          <Box my="auto">
            <Image
              src={
                props.selected
                  ? '/images/deposit-offer-checkbox-selected.svg'
                  : '/images/deposit-offer-checkbox-unselected.svg'
              }
            />
          </Box>
        )}
      </BonusOffer>
    </Checkbox>
  )
}

BonusOfferCard.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.number,
  maxAmountCents: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  translationKeys: PropTypes.array,
  translationVariables: PropTypes.object,
  value: PropTypes.number,
  scope: PropTypes.string.isRequired,
}
