import React from 'react'

import styled from '@emotion/styled'

import { SpinnerIcon } from '../components/common/spinner-icon'

const Wrapper = styled.div`
  display: flex;
  height: 60%;
`

export function Spinner() {
  return (
    <Wrapper>
      <SpinnerIcon />
    </Wrapper>
  )
}
