import * as R from 'ramda'
import url from 'url'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router-dom'

import * as casino from '@rushplay/casino'
import { isSessionActive } from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import * as Constants from '../../constants'
import * as ui from '../../ui'
import * as combinedSelectors from '../../combined-selectors'

import { GameWrapper as Component } from './game-wrapper'

function mapStateToProps(state, props) {
  const game = casino.selectors.getGame(state.casino, {
    id: R.path(['match', 'params', 'gameId'], props),
  })

  const blitzEnabled =
    R.not(R.isNil(R.path(['blitzProperties'], game))) ||
    R.defaultTo(false, R.prop('blitzEnabled', game))
  const authenticated = isSessionActive(state.session)
  const isEmbedded = combinedSelectors.getIsEmbedded(state)
  const isMobile = combinedSelectors.getIsMobile(state)

  return {
    authenticated,
    isEmbedded,
    showFullscreenToggle: isMobile
      ? isEmbedded && process.browser && document.fullscreenEnabled
      : isEmbedded,
    mode: R.path(['mode'], url.parse(props.location.search, true, true).query),
    showModeSelector: blitzEnabled && authenticated,
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    onShowDeposit: () =>
      dispatch(ui.toggleVisibility(Constants.TransactionType.DEPOSIT, true)),
    onModeChange: mode =>
      dispatch(push(`${props.location.pathname}?referrer=casino&mode=${mode}`)),
  }
}

const connector = R.compose(
  withRouter,
  withTranslate,
  connect(mapStateToProps, mapDispatchToProps)
)

export const GameWrapper = connector(Component)
