import * as Recompose from 'recompose'
import * as R from 'ramda'
import { connect } from 'react-redux'

import * as Constants from '../../constants'
import * as Player from '../../player'
import * as Ui from '../../ui'
import * as CombinedSelectors from '../../combined-selectors'

import { BonusOffer as Component } from './bonus-offer'

function mapStateToProps(state) {
  const depositNumber = Player.getDepositCount(state.player)
  const transactionType = Ui.getTransactionType(state.ui)

  return {
    hasDepositOffersAvailable: Player.hasDepositOffersAvailable(state.player),
    bonusOffers: CombinedSelectors.getClaimableDepositOffers(state, {
      depositNumber: depositNumber,
    }),
    isDeposit: R.equals(Constants.TransactionType.DEPOSIT, transactionType),
    welcomeOfferMinDeposit: CombinedSelectors.getWelcomeOfferMinDepositLimit(
      state
    ),
  }
}

const connector = R.compose(
  connect(mapStateToProps),
  Recompose.branch(
    props => R.not(props.isDeposit) || R.not(props.hasDepositOffersAvailable),
    Recompose.renderNothing
  )
)

export const BonusOffer = connector(Component)
