import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import PropTypes from 'prop-types'

import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import * as Constants from '../constants'
import * as FormComponents from '../form-components'
import { H1 } from '../components/common/headings'
import { HtmlContent } from '../components/common/html-content'
import { Payer } from '../payer'

import { BonusOffer } from './bonus-offer'
import { Campaigns } from './campaigns'
import { PendingTransactions } from './pending-transactions'
import { TransactionLimitInfo } from './transaction-limit-info'
import { TransactionStep } from './transaction-steps'
import { TransactionSuccess } from './transaction-success'

const FormWrapper = styled.div`
  ${css({
    pb: 4,
  })};

  height: 100%;

  > form {
    height: 100%;
  }
`

export function Transaction(props) {
  const i18n = I18n.useI18n()
  const location = ReactRouterDom.useLocation()
  const [claimingOfferId, setClamingOfferId] = React.useState(null)
  const step = R.pathOr(TransactionStep.Amount, ['state', 'step'], location)
  const isDeposit = props.transactionType === Constants.TransactionType.DEPOSIT

  // Hack to have initial values update only when step is changed
  const fieldBuilderInitialValues = React.useMemo(
    () => props.fieldBuilderInitialValues,
    [step, props.minAmount]
  )

  React.useEffect(() => {
    props.onInit()
    return () => {
      props.onCleanup()
    }
  }, [])

  return (
    <FormWrapper>
      <Forms.Provider
        schema={props.dataSchema}
        name="transaction"
        onSubmit={(errors, data) => {
          if (R.isEmpty(errors)) {
            if (step === TransactionStep.Amount) {
              if (data.bonusOffer) {
                setClamingOfferId(data.bonusOffer)
                props.onClaimOffer(data.bonusOffer)
              }

              if (data.campaign) {
                // If both are present campaign value should override
                setClamingOfferId(data.campaign)
                props.onClaimCampaign(data.campaign)
              }

              props.onStepChange(TransactionStep.Payer, data)
            }
          }
        }}
      >
        <Common.Box maxWidth="1200" margin="auto" height="100%">
          {step === TransactionStep.Amount && (
            <React.Fragment>
              {props.showPendingTransactions && <PendingTransactions />}

              <Common.Space pb="1">
                <H1
                  color="darkgray"
                  style={{
                    fontSize: '22px',
                    lineHeight: '32px',
                    fontWeight: 700,
                  }}
                  align="center"
                >
                  {i18n.translate(
                    isDeposit ? 'side-bar.deposit' : 'side-bar.cashout'
                  )}
                </H1>
              </Common.Space>

              <FormComponents.WalletInputField
                scope="#/properties/amount"
                initialValue={fieldBuilderInitialValues.amount}
              />
              <Common.Space pb={1}>
                <TransactionLimitInfo transactionType={props.transactionType} />
              </Common.Space>

              <BonusOffer
                scope="#/properties/bonusOffer"
                initialValue={fieldBuilderInitialValues.bonusOffer}
              />

              <Campaigns
                scope="#/properties/campaign"
                initialValue={fieldBuilderInitialValues.campaign}
              />

              <Common.Box pt={1} display="flex" justifyContent="center">
                <FormComponents.SubmitButton>
                  {i18n.translate(
                    `wallet.${props.transactionType}.submit-button`
                  )}
                </FormComponents.SubmitButton>
              </Common.Box>

              {i18n.isExistingTranslation(
                `deposit.${props.license}-disclaimer`
              ) && (
                <Common.Space pt={1}>
                  <Common.Text textAlign="center" fontSize="0">
                    <HtmlContent
                      html={{
                        __html: i18n.translate(
                          `deposit.${props.license}-disclaimer`
                        ),
                      }}
                    />
                  </Common.Text>
                </Common.Space>
              )}
            </React.Fragment>
          )}

          {step === TransactionStep.Payer && (
            <Common.Box height="100%">
              <Payer
                initialData={props.payerInitalValues}
                offerId={claimingOfferId}
                transactionType={props.transactionType}
                onCancel={() => props.onStepChange(TransactionStep.Amount)}
                onSuccess={options => props.onSuccess(options, claimingOfferId)}
              />
            </Common.Box>
          )}

          {step === TransactionStep.TransactionSuccess && (
            <TransactionSuccess
              transactionType={props.transactionType}
              onClose={props.onClose}
            />
          )}
        </Common.Box>
      </Forms.Provider>
    </FormWrapper>
  )
}

Transaction.propTypes = {
  dataSchema: PropTypes.object,
  depositCount: PropTypes.number,
  fieldBuilderInitialValues: PropTypes.object,
  initialValues: PropTypes.object,
  license: PropTypes.string,
  location: PropTypes.object,
  minAmount: PropTypes.number,
  payerInitalValues: PropTypes.object,
  showPendingTransactions: PropTypes.bool,
  transactionType: PropTypes.oneOf([
    Constants.TransactionType.DEPOSIT,
    Constants.TransactionType.WITHDRAWAL,
  ]),
  welcomeOfferMinDeposit: PropTypes.number,
  onClose: PropTypes.func,
  onClaimOffer: PropTypes.func.isRequired,
  onClaimCampaign: PropTypes.func.isRequired,
  onCleanup: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
}
