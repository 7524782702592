import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import RealityCheckPopupContainer from '../reality-check-popup-container'

export function RealityCheck(props) {
  const gameWrapperRef = useRef(null)

  const [shouldDisplay, setShouldDisplay] = useState(false)

  useEffect(() => {
    props.onFetch()
  }, [])

  useEffect(() => {
    function showRealityCheck(event) {
      const action = event.data || {}
      if (action.type === '@rushplay/gamer/REALITY_CHECK_TRIGGERED') {
        return setShouldDisplay(true)
      }
    }

    window.addEventListener('message', showRealityCheck)

    return function cleanup() {
      window.removeEventListener('message', showRealityCheck)
    }
  }, [])

  function onDismiss() {
    props.iframeRef.current.contentWindow.postMessage(
      {
        type: '@rushplay/gamer/REALITY_CHECK_RESET',
      },
      props.gameServerUrl
    )
    setShouldDisplay(false)
  }

  return (
    <React.Fragment>
      <div ref={gameWrapperRef} />
      {shouldDisplay && (
        <RealityCheckPopupContainer
          parentNode={gameWrapperRef.current}
          onDismiss={onDismiss}
        />
      )}
    </React.Fragment>
  )
}

RealityCheck.propTypes = {
  gameServerUrl: PropTypes.string.isRequired,
  iframeRef: PropTypes.object.isRequired,
  onFetch: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
}
