import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router-dom'

import * as session from '@rushplay/session'

import * as ui from '../../../ui'

import { DepositLimitInformation as Component } from './deposit-limit-info'

function mapStateToProps(state) {
  const depositLimits = R.pathOr(
    [],
    ['depositInformation', 'depositLimits'],
    state.player
  )

  const lowestLimit = R.reduce(
    R.min,
    Infinity,
    R.pluck('leftToDepositCents', depositLimits)
  )

  return {
    enabled: session.isSessionActive(state.session),
    limit: R.find(R.propEq('leftToDepositCents', lowestLimit), depositLimits),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    onChangeLimits: () =>
      dispatch([
        ui.toggleVisibility(props.transactionType, false),
        push('/money-limits'),
      ]),
  }
}

const connector = R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  recompose.branch(props => !props.enabled, recompose.renderNothing)
)

export const DepositLimitInformation = connector(Component)
